<template> 

  <div>

    <desordre-list-add-new
      :is-add-new-desordre-sidebar-active.sync="isAddNewDesordreSidebarActive"
      @adddesordre='(indesordre) => addDesordre(indesordre)'  
      :composantsList="arrayComposants"
        :inDesordreData="inDesordreData"      
    />

    <!-- Filters -->
    <desordres-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Rechercher..."
              />
              <b-button
                variant="primary"
                @click="onAddDesordre"
              >
                <span class="text-nowrap">Créer un désordre</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDesordres"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(type)="data">
            <b-link
              @click="onDesordreEdit(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.type }}
            </b-link>
        </template>

        <template #cell(libelle)="data">
            <b-link
              @click="onDesordreEdit(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.libelle }}
            </b-link>
        </template>        

        <!-- Column: Role -->
        <template #cell(composants)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.composants }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(created)="data">
          <span class="align-text-top text-capitalize">     
            {{ data.item.created }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>



      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalDesordres"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import DesordresListFilters from '@/views/apps/desordre/DesordresListFilters.vue'
import useDesordresList from '@/views/apps/desordre/useDesordresList'
import desordreStoreModule from '@/views/apps/desordre/desordreStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DesordreListAddNew from '@/views/apps/desordre/DesordreListAddNew.vue'

export default {
  components: {
    DesordresListFilters,
    DesordreListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    onAddDesordre() {
       this.inDesordreData = {id:''}     
       this.isAddNewDesordreSidebarActive = true      
      //this.$router.push({ name: 'apps-maintenance-add' })
    },    

    onDesordreEdit: function (desordre) { 
       this.inDesordreData = desordre     
       this.isAddNewDesordreSidebarActive = true

  },     
     
    addDesordre: function (desordre) { 
this.isAddNewDesordreSidebarActive = false
        store
            .dispatch('app-desordre/addDesordre', 
                desordre,
            )
            .then(response => {

                desordre = response.data.desordre

                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: 'Désordre créé avec succès',
                    icon: 'EditIcon',
                    variant: 'success',
                    },
                })              
            })
            .catch(() => {
                this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Une erreur est survenue lors de la création',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
                })        
            })
            this.refetchData()
  },     


  },  
  data() {
    return {
      arrayComposants: ['GU156 - A142X - Corniche','GU156 - A143V - Chaussée et revêtement du tablier','GU156 - B51 - Mur de soutènement','GU156 - C1M59 - Bordure'], 
              inDesordreData:{id:''}, 
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-desordre'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, desordreStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewDesordreSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Planifié', value: 'planifié' },
      { label: 'En cours', value: 'en cours' },
      { label: 'Terminé', value: 'terminé' },
      { label: 'En retard', value: 'retard' },
      { label: 'Rapport', value: 'rapport' },
    ]

    const {
      fetchDesordres,
      tableColumns,
      perPage,
      currentPage,
      totalDesordres,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      statusFilter,
    } = useDesordresList()

    return {

      // Sidebar
      isAddNewDesordreSidebarActive,

      fetchDesordres,
      tableColumns,
      perPage,
      currentPage,
      totalDesordres,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
